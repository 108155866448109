import React from 'react'
import Router from "./Router/Router"

const App = () => {

  return (
    <div >
      
      <Router />


    </div>
  );
};

export default App;
